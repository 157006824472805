import React from "react"
import ReactCountryFlag from "react-country-flag"
const Countries = [
    {
        id: 1,
        icon: < ReactCountryFlag countryCode="AU" svg className="country-icon" />,
        title: "Australia",
        text: `Currently based in Australia, I work as a software developer, collaborating with diverse teams to deliver high-quality software solutions. 
        With my expertise in coding and problem-solving, I contribute to projects that drive innovation and improve user experiences in various industries.`,
    },
    {
        id: 2,
        icon: < ReactCountryFlag countryCode="DE" svg className="country-icon" />,
        title: "Germany",
        text: `During my visit to Berlin in 2019, I was captivated by the city's rich history and cultural landmarks, including the iconic Berlin Wall.`,
    },
    {
        id: 3,
        icon: < ReactCountryFlag countryCode="EE" svg className="country-icon" />,
        title: "Estonia",
        text: `After exploring the charming streets of Tallinn, the capital city of Estonia, in 2019, I was enamored by its blend of medieval history and modern innovation. 
        My time in Estonia sparked a deep appreciation for its culture and technology-driven society`,
    },
    {
        id: 4,
        icon: < ReactCountryFlag countryCode="TH" svg className="country-icon" />,
        title: "Thailand",
        text: `In 2019, I embarked on a backpacking adventure through Thailand, immersing myself in the vibrant culture of Bangkok and the lively atmosphere of Pattaya city, where I stayed in a bustling party hostel. 
        The highlight of my trip was spending two unforgettable days on Coral Island (Koh Larn), surrounded by crystal-clear waters and stunning beaches. 
        These experiences left me with cherished memories and a deep appreciation for the beauty and diversity of Thailand.`,
    },
    {
        id: 5,
        icon: < ReactCountryFlag countryCode="QA" svg className="country-icon" />,
        title: "Qatar",
        text: `Transiting through Doha on my flight with Qatar Airways. Got new transit visa free of cost without any additional fare charges.`,
    },
    {
        id: 6,
        icon: < ReactCountryFlag countryCode="IN" svg className="country-icon" />,
        title: "India",
        text: `In 2019, I explored the diverse landscapes and cultures of India, venturing to Mumbai, Pune, and Gorakhpur. 
        From the bustling streets of Mumbai to the historical sites of Pune and the vibrant markets of Gorakhpur, every city offered a unique experience. 
        India's rich history, delicious cuisine, and warm hospitality made my journey unforgettable, leaving me with a deeper appreciation for its people and traditions.`,
    },
    {
        id: 7,
        icon: < ReactCountryFlag countryCode="NP" svg className="country-icon" />,
        title: "Nepal",
        text: `In my homeland of Nepal, I've trekked through the majestic Annapurna Base Camp, explored the breathtaking landscapes of Solukhumbu District in the east, and ventured into the rugged terrain of Western Nepal. 
        Each journey has been a testament to Nepal's natural beauty and cultural richness, with its towering mountains, serene valleys, and vibrant communities leaving an indelible mark on my soul.
         Nepal will always hold a special place in my heart, drawing me back time and again to its unparalleled wonders.`,
    }
]
export default Countries